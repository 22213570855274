import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
	static values = {
		appId: String,
		planId: String,
		discount: {
			type: Number,
			default: null
		}
	}

	static targets = ["loadable", "price", "originalPrice", "placeholder"]

	async connect() {
		try {
			if (sessionStorage.getItem(this.storageKey)) {
				const price = JSON.parse(sessionStorage.getItem(this.storageKey))
				this.setComponents(price)
			} else {
				const response = await get("https://licenses.panini.house/purchases/prices/", {
					query: {
						app_id: this.appIdValue,
						plan_id: this.planIdValue,
						discount: this.discountValue ?? ""
					},
					contentType: "application/json",
					responseKind: "json"
				})

				if (response.ok) {
					const price = await response.json
					sessionStorage.setItem(this.storageKey, JSON.stringify(price))
					this.setComponents(price)
				}
			}
		} catch(error) {
			console.error(error)
		} finally {
			this.loadableTargets.forEach(loadable => loadable.classList.remove("pricing_card__price--loading"))
			this.placeholderTargets.forEach(placeholder => placeholder.classList.add("hidden"))
		}
	}

	setComponents(price) {
		if (price?.format != null) {
			this.priceTarget.innerHTML = price.format
		}

		if (price?.original_price?.format != null && this.hasOriginalPriceTarget) {
			this.originalPriceTarget.innerHTML = price.original_price.format
		}
	}

	get storageKey() {
		return `${this.appIdValue}_${this.planIdValue}_price`
	}
}
